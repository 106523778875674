@import "../../../styles/main";

.promotion-carousel {
  width: 100%;
  display: block;
  height: auto;
  overflow: hidden;
  position: relative;
  margin: 16px 0 16px 0;

  @media #{$desktop} {
    margin: 32px 0 0 0;
  }

  .rec-slider-container {
    margin: 0;
  }

  .rec-pagination {
    position: absolute;
    bottom: 1px;

    .rec-dot {
      background-color: white;
      box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    }

    .rec-dot {
      cursor: pointer;
      background-color: rgba(255,255,255,.5);
      box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    }

    .rec-dot_active {
      background-color: rgba(255,255,255,1);
    }
  }

  .rec-item-wrapper > .promotion-item,
  .promotion-item {
    cursor: pointer;
    position: relative;
    height: auto;
    overflow: hidden;
    width: 100%;
    height: 202px;
    width: 100%;
    margin: 16px;
    border: none;
    min-height: 100%;
    padding: 0;
    box-shadow: 0 1px 3px 0 rgba(100, 97, 97, 0.1);
    border-radius: 8px;

    @media #{$tablet} {
      border-radius: 12px;
    }
  }

  .promotion-item {
    margin: 0 0 0 16px;
    width: calc(100% - 32px)
  }

  .rec-item-wrapper > .promotion-item > .promotion-info.hide,
  .promotion-item > .promotion-info {
    //display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0,0,0,.75), rgba(0,0,0,.25));

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.3em;
    border-radius: 8px;

    @media #{$tablet} {
      border-radius: 12px;
    }
  }

  .rec-item-wrapper > .promotion-item > .promotion-info > .promotion-caption,
  .promotion-item > .promotion-info > .promotion-caption {
    //display: block;
    .promotion-title {
      font-family: inherit;
      font-size: 1.6em;
      font-weight: $font-bold;
      line-height: 1.2em;
      color: $color-white-two;
      margin: 0 0 .35em 0;
      //padding: 0px 24px
    }
    .promotion-description {
      font-family: inherit;
      font-size: 1.1em;
      font-weight: 500;
      line-height: 1.3em;
      color: $color-white-two;
      margin: 0.35em 0;
      //padding: 0px 24px
    }
  }

  .rec-item-wrapper > .promotion-item > .promotion-info > .promotion-button,
  .promotion-item > .promotion-info > .promotion-button {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 2em;
    padding: 0 1em;
    background-color: white;
    color: #000000;
    font-weight: bold;
    text-transform: none;
    height: 2em;
    position: inherit;
    bottom: 20px;
    left: 22px;

    svg {
      margin-left: 0.8em;
    }
  }

  .rec-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 204px;
    width: 100%;
    background-color: transparent;
    color: #fff;
  }

  .rec-item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .rec-item-wrapper > .promotion-item > img,
  .promotion-item > img {
    width: 100%;
    height: 204px;
    object-fit: cover;
    object-position: center;
    margin: 0;
    padding: 0;
    border: none;

    .no-image {
      position: absolute;
    }
  }
}

.hide {
  display: none;
}
