@import "../../../styles/main";

$max-width: 300px;
$max-width-2: $max-width * 1.5;
$height: 45px;
$padding-li: 1em * 2; // 1.5em is the padding of all <li> in Topbar

.bu {
  -webkit-transition: flex-grow 500ms;
  -moz-transition: flex-grow 500ms;
  transition: flex-grow 500ms;
}

.bt {
  max-width: unset;
  @media #{$tabletDesktop} {
    max-width: $max-width-2;
  }
}

.bs {
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.af {
  position: relative;
}

.d8 {
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.button-search {
  display: flex;
  justify-content: left;
  align-items: center;
  border: none;
  max-width: unset;
  @media #{$tabletDesktop} {
    max-width: $max-width;
  }

  background: white;
  @media #{$tabletDesktop} {
    background: transparent;
  }

  width: 100%;
  padding: 0.35em 0.5em;
  letter-spacing: normal !important;

  img {
    margin-right: o;
    @media #{$tabletDesktop} {
      margin-right: 5px;
    }
  }

  span {
    display: none;
    @media #{$tabletDesktop} {
      display: inherit;
    }
  }

  div {
    background: transparent;

    flex-grow: 1;

    text-overflow: ellipsis;
    line-height: 24px;
    text-transform: none !important;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
  }
}

.react-autosuggest {
  &__container {
    position: relative;
    // width: $width;
    max-width: unset;
    @media #{$tabletDesktop} {
      max-width: $max-width-2;
    }
  }

  &__input {
    //width: $width;
    max-width: unset;
    height: $height !important;
    // padding: 10px 20px;
    padding: 0 1em !important;
    font-weight: 400;
    font-size: 16px !important;
    border: none;
    background-color: #f6f6f6 !important;
    border-bottom: 1px solid #f6f6f6;
    &:focus {
      outline: none;
    }
  }

  &__container--open &__input {
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;
  }

  &__suggestions-container {
    display: none;
  }

  &__container--open &__suggestions-container {
    display: block;
    position: absolute;
    top: 51px;
    left: 0px;
    width: $max-width;
    max-width: unset;
    @media #{$tabletDesktop} {
      width: $max-width-2;
      max-width: unset;
    }
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    z-index: 2;
  }
  &__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline;
    display: flex;
    flex-direction: column;
  }
  &__suggestion {
    cursor: pointer;
    display: block !important;
    // width: calc(100% - #{$padding-li}) !important;
    width: 100% !important;
    padding: 0 !important;
    &:hover {
      background-color: #f6f6f6;
    }
    &:first-child {
      margin-top: 0.5em;
    }
    &:last-child {
      margin-bottom: 0.5em;
    }
    &--focused {
      background-color: #f6f6f6;
      color: #fff;
    }

    .suggestion__inner {
      display: flex;
      height: 56px;
      align-items: center;
      padding: 0 0 0 1em;
    }
    .suggestion-label {
      height: 100%;
      width: 100%;
      margin-left: 1em;
      display: flex;
      align-items: center;
      div {
        flex-grow: 1;
        text-align: left;
      }

      img,
      .avatar {
        padding-right: 1em;
      }
    }

    &:not(:last-child) > .suggestion__inner > .suggestion-label {
      border-bottom: 1px solid #f6f6f6;
    }
  }
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  height: 27px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    width: auto;
  }
}

.autosuggest-container {
  display: flex;
  align-items: center;
  & > div {
    flex-grow: 1;
    & > input {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      background-color: #f6f6f6 !important;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #5d5d5d !important;
        opacity: 1; /* Firefox */
      }
    }
  }
  & > button {
    border: none;
    border-bottom: 1px solid #f6f6f6;
    background-color: #f6f6f6 !important;
    height: $height;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}
