@import "../../styles/main";

$zindex-base: 500;

.mydialog {
  &__container {
    overflow: auto; // overlay
    z-index: $zindex-base;
    height: 100vh;
    background: rgba(38, 38, 38, 0.8);
    right: 0;
    left: 0;
    top: 0;
    position: fixed;
  }

  &__wrapper {
    min-height: 100vh;
    -webkit-box-pack: start;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 auto;
  }

  &__placeholder {
    height: 0px; // 60px
    width: 100%;
  }

  &__dialog {
    background: #ffffff;
    margin: 0 auto;
    position: relative;
    border-radius: 8px;

    @media #{$tablet} {
      border-radius: 12px;
    }

    &__header {
      position: sticky;
      background: red;
      top: 0;

      &__inner {
        height: 0;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: $zindex-base + 3;

        .title {
          transition: height 300ms ease 0s, opacity 300ms ease 0s;
          height: 0;
          background: white;
          width: 100%;
          opacity: 0;
          align-items: center;
          display: flex;
          position: absolute;

          &::before {
            flex: 1 0 48px;
            content: "";
          }

          &__text {
            flex: 1 1 auto;
            text-align: center;
            padding: 12px;
            font-size: 18px;
            text-overflow: ellipsis;
            line-height: 24px;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
          }

          &::after {
            flex: 1 10000 48px;
            content: "";
          }

          &-sticky {
            opacity: 1;
            box-shadow: rgb(226, 226, 226) 0px -2px 0px inset;
            height: 64px;
          }
        }

        .close {
          position: absolute;
          left: 1em;
          top: 1.5em;
          width: 48px;
          height: 48px;
          z-index: $zindex-base + 1;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $color-white-two;
          color: black;
          box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.04) 0px 4px 4px;
          &:hover,
          &:focus {
            background: $color-black;
            & > svg {
              fill: $color-white-two !important;
            }
          }

          &-topped {
            top: .6em;
          }
        }
      }

      &__hold {
        z-index: $zindex-base + 4;
        top: 0;
        position: relative; // it changes to sticky
        &-sticky {
          position: sticky;
        }
      }
    }

    &__content {
      width: 650px;
      position: relative;

      &--dense {
        width: 450px;
      }

      &__inner {
        min-height: 60vh;
        width: 100%;
      }

      &__image {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        height: 400px;
        width: 100%;

        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        img {
          width: 100%;
        }

        .sold-out {
          top: 0;
          left: 0;
          width: 100%;
          min-width: 35em;
          height: 100%;
          padding-top: 25em;
          position: relative;
          text-transform: uppercase;
          //background-color: rgba(34, 34, 34, 0.74);
          background-color: rgba(255, 255, 255, 0.5);
        }

        .hide {
          display: none;
        }
      }

      &__caption {
        padding: 24px 61px 0;

        h1, h2, h3, h4, h5, h5 {
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          margin: 0;
          font-weight: normal;
        }

        div {
          text-align: left;
          padding-top: 16px;
          color: rgb(84, 84, 84);
          line-height: 20px;
          font-size: 14px;
        }
      }

      &__description {
        text-align: left;
        white-space: break-spaces;
      }

      &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        &__head {
          padding: 16px 24px;
          background: rgb(246, 246, 246);
          text-align: left;

          &__title {
            font-size: 18px;
            line-height: 24px;
            color: #000000;
          }

          &__subtitle {
            color: rgb(84, 84, 84);
            line-height: 20px;
            font-size: 14px;
          }
        }

        &__body {
          padding: 24px;
          display: flex;
          flex-direction: column;

          &__item {
            display: flex;
            align-items: center;

            &__name {
              flex: 1;
              line-height: 16px;
              font-size: 14px;
              color: #000000;
              text-align: left;
            }

            &__price {
              color: rgb(84, 84, 84);
              line-height: 20px;
              font-size: 14px;
              white-space: nowrap;
            }
          }
        }
      }

      .divition {
        background-color: rgb(226, 226, 226);
        margin: 0;
        height: 1px;
        border: none;
      }
    }

    &__footer {
      background: linear-gradient(
                      rgba(255, 255, 255, 0) 2.5%,
                      rgba(255, 255, 255, 0.92) 55.35%
      );
      position: sticky;
      bottom: 0;
      padding: 15px 15px 0 15px;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      display: flex;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none;
      z-index: $zindex-base + 9;

      button {
        height: 30px;
        padding: 10px 14px;
        font-size: 16px;
        @media #{$tabletDesktop} {
          height: 32px;
          padding: 12px 16px;
          font-size: 18px;
        }

        &:first-of-type {
          margin-left: 0;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        min-height: 56px;
        line-height: 24px;

        .button__prefix {
          flex: 1;
        }

        .button__label {
          flex: 1;
          text-transform: capitalize;
          text-align: left;
        }

        .button__price {
          flex: 1;
          white-space: nowrap;
          text-align: right;
        }
      }
    }

    .stock-hint {
      width: 100%;
      padding: .5em 0;
      color: $color-metallic-blue;
      font-size: .8em;
    }

    .required {
      color: red;
    }
  }
}
